import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {subscribed} from "./apiCalls";

const initialState = {
    isErrorSubs: false,
    isSuccessSubs: false,
    isLoadingSubs: false,
    messageSubs: "",
};

// Get Highlight
export const getSubs = createAsyncThunk(
    "newsletter/getSubs",
    async (email, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await subscribed(email);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const newsletterSlice = createSlice({
    name: "newsletter",
    initialState,
    reducers: {
        resetSubs: (state) => {
            state.isErrorSubs = false;
            state.isSuccessSubs = false;
            state.isLoadingSubs = false;
            state.messageSubs = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubs.pending, (state) => {
                state.isLoadingSubs = true;
            })
            .addCase(getSubs.fulfilled, (state, action) => {
                state.isLoadingSubs = false;
                state.isSuccessSubs = true;
                state.messageSubs = action.payload;
            })
            .addCase(getSubs.rejected, (state, action) => {
                state.isLoadingSubs = false;
                state.isErrorSubs = true;
                state.messageSubs = action.payload;
            });
    },
});

export const {resetSubs} = newsletterSlice.actions;
export default newsletterSlice.reducer;
