import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "reactstrap";

import heroImg2 from "../assets/image/header-cat.jpg";

import "../styles/hero-section.css";
import "../styles/category.css";

import DealsList from "../components/DealsList";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { useSelector, useDispatch } from "react-redux";
import { getDeals } from "../redux/apiCalls";
import {
    getAllDeals,
    getDealsByCategoryData,
    reset,
} from "../redux/dealsRedux";
import { getAllCategoriesData, resetAllCategory } from "../redux/categoryRedux";

import { Link, useParams } from "react-router-dom";

import { motion } from "framer-motion";

const CategoryListing = () => {
    const params = useParams();

    const categoryId = params.categoryId;

    const [catTitle, setCatTitle] = useState("");
    const [sort, setSort] = useState("");
    const [deals, setDeals] = useState([]);

    const {
        categoryDeals,
        isLoadingCategoryDeals,
        isErrorCategoryDeals,
        messageCategoryDeals,
    } = useSelector((state) => state.deals);

    const {
        allCategories,
        isLoadingAllCategories,
        isErrorAllCategories,
        messageAllCategories,
    } = useSelector((state) => state.category);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isErrorCategoryDeals) {
            console.log(messageCategoryDeals);
        }

        dispatch(reset());
        dispatch(getDealsByCategoryData(categoryId));

        return () => {
            dispatch(reset());
        };
    }, [categoryId, isErrorCategoryDeals, messageCategoryDeals, dispatch]);

    useEffect(() => {
        if (isErrorAllCategories) {
            console.log(messageAllCategories);
        }

        dispatch(getAllCategoriesData());

        return () => {
            dispatch(resetAllCategory());
        };
    }, [isErrorAllCategories, messageAllCategories, dispatch]);

    useEffect(() => {
        if (allCategories.length !== 0) {
            const found = allCategories.find((obj) => {
                return obj._id === categoryId;
            });

            setCatTitle(found.title);
        }
    }, [allCategories, categoryId]);

    useEffect(() => {
        if (categoryDeals.length !== 0) {
            setDeals(categoryDeals);
        }
    }, [categoryDeals]);

    useEffect(() => {
        if (sort === "new") {
            setDeals((prev) =>
                [...prev].sort((a, b) => b.dealPrice - a.dealPrice)
            );
        } else if (sort === "asc") {
            setDeals((prev) =>
                [...prev].sort((a, b) => a.dealPrice - b.dealPrice)
            );
        } else if (sort === "desc") {
            setDeals((prev) =>
                [...prev].sort((a, b) => b.dealPrice - a.dealPrice)
            );
        }
    }, [sort]);

    return (
        <div>
            <Header type="wImg" />
            <section className="hero__section">
                <div className="hero__content">
                    {allCategories.length !== 0 && <h5>{catTitle}</h5>}
                </div>
                <div className="hero__img">
                    <img src={heroImg2} alt="hero-img" className="w-100" />
                </div>
            </section>

            <section className="category__section">
                <Container>
                    <Row>
                        <Col className="heading__category">
                            <h5>Category</h5>
                            <select
                                name="sort"
                                className="filter__option"
                                onChange={(e) => setSort(e.target.value)}
                            >
                                <option value="">Sorting</option>
                                <option value="asc">Lowest Price</option>
                                <option value="desc">Highest Price</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" className="category__column">
                            <ul>
                                {allCategories.map((item) => (
                                    <Link
                                        to={`/category/${item._id}`}
                                        key={item._id}
                                    >
                                        <li>{item.title}</li>
                                    </Link>
                                ))}
                            </ul>
                        </Col>
                        <Col lg="9" className="deals__column">
                            {deals.length !== 0 ? (
                                deals.map((item) => (
                                    <DealsList key={item._id} item={item} />
                                ))
                            ) : (
                                <div>
                                    <h5 className="no__found">
                                        No Deals Found.
                                    </h5>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default CategoryListing;
