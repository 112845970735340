import React from "react";

import { Container, Row, Col } from "reactstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Header2 from "../components/Header2";

import "../styles/about-us.css";

import { motion } from "framer-motion";

const About = () => {
    return (
        <div>
            <Header type="woImg" />
            <section className="section__about">
                <Container>
                    <Row>
                        <Col lg="12" className="text-center">
                            <h5>About Us</h5>
                            <p className="about__content">
                                Welcome to the Meruorah Deals, where you can
                                savor every moment which is created to be
                                enticing. Embrace the beauty of Labuan Bajo and
                                the archipelago of Komodo with Meruorah Deals in
                                one package. Get and relish everything with us
                                and we will make it easier for you.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default About;
