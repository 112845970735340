import {useState} from "react";

import {Container, Row, Col} from "reactstrap";
import Footer from "../components/Footer";
import Header2 from "../components/Header2";

import {register} from "../redux/apiCalls";
import {useDispatch, useSelector} from "react-redux";

import "../styles/email-verify.css";

import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "../components/Header";

import {motion} from "framer-motion";

import {toast} from "react-toastify";
import {useEffect} from "react";

import axios from "axios";

import icon from "../assets/image/success.png";

const EmailVerify = () => {
    const navigate = useNavigate();

    const [validUrl, setValidUrl] = useState(false);

    const param = useParams();

    useEffect(() => {
        const verifyEmailUrl = async () => {
            try {
                const url = `https://api.meruorahdeals.com/api/auth/verification/${param.verificationCode}`;
                const {data} = await axios.get(url);
                console.log(data);
                setValidUrl(true);
            } catch (error) {
                console.log(error);
                setValidUrl(false);
            }
        };
        verifyEmailUrl();
    }, [param]);

    return (
        <div>
            <Header type="woImg" />
            <section className="login__section">
                <Container>
                    <Row>
                        <Col
                            lg="5"
                            md="6"
                            sm="12"
                            className="m-auto success_verfiy"
                        >
                            <div>
                                <span className="title__success">
                                    Login Email Verified
                                </span>
                            </div>
                            <div>
                                <img
                                    src={icon}
                                    alt="icon-success"
                                    className="icon__success"
                                />
                            </div>
                            <div className="success__link">
                                <Link to="/login">
                                    <button className="btn__success">
                                        Login
                                    </button>
                                </Link>
                                <Link to="/">
                                    <button className="btn__success">
                                        Home
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default EmailVerify;
