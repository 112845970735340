import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllVendor } from "./apiCalls";

const initialState = {
    vendors: [],
    isErrorVendors: false,
    isSuccessVendors: false,
    isLoadingVendors: false,
    messageVendors: "",
};

// Get Vendor
export const getAllVendorData = createAsyncThunk(
    "vendors/getAllVendorData",
    async (_, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await getAllVendor();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const vendorSlice = createSlice({
    name: "vendor",
    initialState,
    reducers: {
        resetVendors: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllVendorData.pending, (state) => {
                state.isLoadingVendors = true;
            })
            .addCase(getAllVendorData.fulfilled, (state, action) => {
                state.isLoadingVendors = false;
                state.isSuccessVendors = true;
                state.vendors = action.payload;
            })
            .addCase(getAllVendorData.rejected, (state, action) => {
                state.isLoadingVendors = false;
                state.isSuccessVendors = true;
                state.messageVendors = action.payload;
            });
    },
});

export const { resetVendors } = vendorSlice.actions;
export default vendorSlice.reducer;
