import {createSlice} from "@reduxjs/toolkit";

import {toast} from "react-toastify";

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        isFetching: false,
        error: false,
    },
    reducers: {
        updateStart: (state) => {
            state.isFetching = true;
        },
        updateSuccess: (state, action) => {
            state.isFetching = false;

            //toast.success("Register successfull. Please login to your account.", { position: "top-right" });

            //state.currentUser = action.payload;

            //localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
        },
        updateFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
