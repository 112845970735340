import {useEffect, useState} from "react";

import {Container, Row, Col} from "reactstrap";
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import {googleLogin, login} from "../redux/apiCalls";
import {useDispatch, useSelector} from "react-redux";

import "../styles/login.css";
import Header from "../components/Header";

import {motion} from "framer-motion";
import {Link} from "react-router-dom";

import {GoogleLogin} from "react-google-login";
import {gapi} from "gapi-script";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const {isFetching} = useSelector((state) => state.user);

    const handleClick = (e) => {
        e.preventDefault();
        login(dispatch, {email, password});
    };

    const responseSuccessGoogle = (response) => {
        googleLogin(dispatch, {
            fname: response.profileObj.familyName,
            lname: response.profileObj.givenName,
            email: response.profileObj.email,
            password,
        });
        //console.log(response);
    };

    const responseErrorGoogle = (response) => {
        console.log(response);
    };

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId:
                    "430106331673-7ltsnt0t22vr11d8vqghk1absml9noft.apps.googleusercontent.com",
                scope: "email",
                plugin_name: "Meruorah Deals",
            });
        }

        gapi.load("client:auth2", start);
    }, []);

    return (
        <div>
            <Header type="woImg" />
            <section className="login__section">
                <Container>
                    <Row>
                        <Col
                            lg="5"
                            md="6"
                            sm="12"
                            className="m-auto text-center login__content"
                        >
                            <h5>Login</h5>
                            <form className="form">
                                <div className="form__group gap-2">
                                    <i className="ri-mail-line"></i>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div className="form__group gap-2">
                                    <i className="ri-lock-line"></i>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="login__btn"
                                    disabled={isFetching}
                                    onClick={handleClick}
                                >
                                    {isFetching ? "Loading..." : "Login"}
                                </button>
                            </form>
                            <div className="link__forgot">
                                <Link to={`/forgot-password`}>
                                    Forgot Password ?
                                </Link>
                            </div>
                            <GoogleLogin
                                clientId="430106331673-7ltsnt0t22vr11d8vqghk1absml9noft.apps.googleusercontent.com"
                                buttonText="Login with Google"
                                onSuccess={responseSuccessGoogle}
                                onFailure={responseErrorGoogle}
                                cookiePolicy={"single_host_origin"}
                            />
                            <div className="link__register">
                                Don't have an account ?{" "}
                                <Link to={`/register`}>Create an account.</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default Login;
