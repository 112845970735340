import React, {useEffect, useState} from "react";

import {Container, Row, Col, Table} from "reactstrap";

import heroImg2 from "../assets/image/header-cat.jpg";

import "../styles/hero-section.css";
import "../styles/order-history.css";

import DealsList from "../components/DealsList";
import Header from "../components/Header";
import Footer from "../components/Footer";

import {useSelector, useDispatch} from "react-redux";
import {getDeals, updateProfile} from "../redux/apiCalls";
import {getAllDeals, getDealsByCategoryData, reset} from "../redux/dealsRedux";
import {getAllCategoriesData, resetAllCategory} from "../redux/categoryRedux";

import {Link, useParams} from "react-router-dom";

import {motion} from "framer-motion";

import {toast} from "react-toastify";
import {publicRequest} from "../requestMethods";
import NumberFormat from "react-number-format";

const OrderHistory = () => {
    const userData = useSelector((state) => state.user.currentUser);

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const getUserOrders = async () => {
            try {
                const res = await publicRequest.get(
                    "/orders/find/" + userData._id
                );
                // console.log(res.data);
                setOrders(res.data);
            } catch (error) {
                console.log(error);
            }
        };

        getUserOrders();
    }, [userData]);

    return (
        <div>
            <Header type="woImg" />

            <section className="profile__section">
                <Container>
                    <Row>
                        <Col lg="3" className="profile_nav__column">
                            <div className="avatar">
                                <div className="avatar_icon">
                                    <i className="ri-user-line"></i>
                                </div>
                                <h5 className="profile_email">
                                    {userData.email}
                                </h5>
                            </div>
                            <ul>
                                <Link to={`/profile`}>
                                    <li>Profile</li>
                                </Link>

                                <Link to={`/order-history`}>
                                    <li>Order History</li>
                                </Link>
                            </ul>
                        </Col>
                        <Col lg="9" className="profile__column">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order Date</th>
                                        <th>Package</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.length > 0 ? (
                                        orders.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.createdAt}</td>
                                                <td>
                                                    {item.packages.map(
                                                        (datapack) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        datapack._id
                                                                    }
                                                                >
                                                                    {
                                                                        datapack.title
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={item.amount}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"IDR "}
                                                        renderText={(value) =>
                                                            value
                                                        }
                                                    />
                                                </td>
                                                <td>{item.status}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>No Orders.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default OrderHistory;
