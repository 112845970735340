import React from "react";

import "../styles/cart-item.css";

import {useDispatch} from "react-redux";
import {cartActions} from "../redux/cartRedux";
import NumberFormat from "react-number-format";

import parser from "html-react-parser";

const CartItem = ({item}) => {
    const truncate = function (str) {
        return str.length > 10 ? str.substring(0, 120) + "..." : str;
    };

    const {_id, title, price} = item;

    const dispatch = useDispatch();

    const incrementItem = () => {
        dispatch(
            cartActions.addItem({
                _id,
                title,
                price,
            })
        );
    };

    const decrementItem = () => {
        dispatch(cartActions.removeItem(_id));
    };

    const deleteItem = () => {
        dispatch(cartActions.deleteItem(_id));
    };

    return (
        <div className="cart__item">
            <div className="cart__item-info d-flex gap-2">
                {/* <img src="" alt="" /> */}

                <div className="cart__packages-info w-100 d-flex align-items-center gap-4 justify-content-between">
                    <div>
                        <h5 className="cart__packages-title">{item.title}</h5>

                        <p className="d-flex align-items-center gap-2 cart__packages-price">
                            {item.quantity}x{" "}
                            <span>
                                <NumberFormat
                                    value={item.totalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"IDR "}
                                    renderText={(value) => value}
                                />
                            </span>
                        </p>
                        <div className="d-flex align-items-center justify-content-between increase__decrease-btn">
                            <span
                                className="increase__btn"
                                onClick={incrementItem}
                            >
                                <i className="ri-add-line"></i>
                            </span>
                            <span className="quantity">{item.quantity}</span>
                            <span
                                className="decrease__btn"
                                onClick={decrementItem}
                            >
                                <i className="ri-subtract-line"></i>
                            </span>
                        </div>
                    </div>

                    <div className="cart__desc">
                        {parser(`${truncate(item.desc)}`)}
                    </div>

                    <span className="delete__btn" onClick={deleteItem}>
                        <i className="ri-close-line"></i>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
