import {userActions} from "./userRedux";
import {registerActions} from "./registerRedux";
import {forgotActions} from "./forgotRedux";
import {resetActions} from "./resetRedux";
import {profileActions} from "./profileRedux";
import {dealsActions} from "./dealsRedux";
import {orderActions} from "./orderRedux";
import {publicRequest} from "../requestMethods";
import axios from "axios";

import {toast} from "react-toastify";
import {googleActions} from "./googleRedux";

// const API_URL = "http://localhost:5000/api/";
const API_URL = "https://api.meruorahdeals.com/api/";

export const login = async (dispatch, user) => {
    dispatch(userActions.loginStart());

    try {
        const res = await publicRequest.post("/auth/login", user);

        dispatch(userActions.loginSuccess(res.data));
    } catch (error) {
        dispatch(userActions.loginFailure());
        toast.error("Something went wrong...", {position: "top-right"});
    }
};

export const googleLogin = async (dispatch, token) => {
    dispatch(googleActions.loginStart());

    try {
        const res = await publicRequest.post("/auth/googlelogin", token);

        dispatch(googleActions.loginSuccess(res.data));
    } catch (error) {
        dispatch(googleActions.loginFailure());
        toast.error("Something went wrong...", {position: "top-right"});
    }
};

export const register = async (dispatch, register) => {
    dispatch(registerActions.registerStart());

    try {
        const res = await publicRequest.post("/auth/register", register);

        dispatch(registerActions.registerSuccess(res.data));
    } catch (error) {
        dispatch(registerActions.registerFailure());
        toast.error("Something went wrong...", {position: "top-right"});
    }
};

export const updateProfile = async (dispatch, profile) => {
    dispatch(profileActions.updateStart());

    try {
        const res = await publicRequest.post("/auth/update-profile", profile);

        dispatch(profileActions.updateSuccess(res.data));
    } catch (error) {
        dispatch(profileActions.updateFailure());
        toast.error("Something went wrong...", {position: "top-right"});
    }
};

export const forgotPassword = async (dispatch, forgot) => {
    dispatch(forgotActions.forgotStart());

    try {
        const res = await publicRequest.post("/auth/forgot-password", forgot);

        dispatch(forgotActions.forgotSuccess(res.data));
    } catch (error) {
        dispatch(forgotActions.forgotFailure());
        toast.error("Email doesn't exists!", {position: "top-right"});
    }
};

export const resetPassword = async (dispatch, reset) => {
    dispatch(resetActions.resetStart());

    try {
        const res = await publicRequest.post("/auth/reset-password", reset);

        dispatch(resetActions.resetSuccess(res.data));
    } catch (error) {
        dispatch(resetActions.resetFailure());
        toast.error("Something went wrong...", {position: "top-right"});
    }
};

export const getDeals = async () => {
    //const res = await axios.get(API_URL + "deals?new=true");
    const res = await publicRequest.get("deals?new=true");

    return res.data;
};

export const getHighlight = async () => {
    //const res = await axios.get(API_URL + "deals?new=true");
    const res = await publicRequest.get("deals/highlight");

    return res.data;
};

export const getFeatured = async () => {
    //const res = await axios.get(API_URL + "deals?new=true");
    const res = await publicRequest.get("deals/featured");

    return res.data;
};

export const getDealsByCategory = async (id) => {
    //const res = await axios.get(API_URL + "deals?new=true");
    const res = await publicRequest.get("deals/bycategory/" + id);

    return res.data;
};

export const getAllCategories = async () => {
    const res = await publicRequest.get("categories");

    return res.data;
};

export const getNavCategories = async () => {
    const res = await publicRequest.get("categories/nav-cat");

    return res.data;
};

export const getAllVendor = async () => {
    const res = await publicRequest.get("vendors");

    return res.data;
};

export const order = async (dispatch, order) => {
    dispatch(orderActions.orderStart());

    try {
        const res = await publicRequest.post("/orders", order);

        dispatch(orderActions.orderSuccess(res.data));
    } catch (error) {
        dispatch(orderActions.orderFailure());
        toast.error("Something went wrong...", {position: "top-right"});
    }
};

export const subscribed = async (email) => {
    //const res = await axios.get(API_URL + "deals?new=true");
    const res = await publicRequest.get("newsletter?email=" + email);

    return res.data;
};

export const getUserProfile = async (id) => {
    //const res = await axios.get(API_URL + "deals?new=true");
    const res = await publicRequest.get("auth/find/" + id);

    return res.data;
};
