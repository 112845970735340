import {useEffect} from "react";

import {Container, Row, Col} from "reactstrap";

import heroImg from "../assets/image/header3.jpg";

import "../styles/hero-section.css";
import "../styles/home.css";

import Highlight from "../components/Highlight";
import DealsCard from "../components/DealsCard";

import pinta from "../assets/image/logo-pinta.png";
import eastcruise from "../assets/image/eastcruise.jpg";
import dpk from "../assets/image/dpk.png";
import Header from "../components/Header";
import Footer from "../components/Footer";

import {useDispatch, useSelector} from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {getBestDeals, reset} from "../redux/dealsRedux";
import {getHighlightDeals, resetHighlight} from "../redux/highlightRedux";

import Spinner from "../components/Spinner";
import {useState} from "react";
import {getFeaturedDeals, resetFeatured} from "../redux/featuredRedux";
import HTMLReactParser from "html-react-parser";
import {Link} from "react-router-dom";

import {motion} from "framer-motion";
import {getAllVendorData, resetVendors} from "../redux/vendorRedux";

const Home = () => {
    //const deals = useSelector((state) => state.deals.currentDeals);
    const {currentDeals, isLoading, isError, message} = useSelector(
        (state) => state.deals
    );
    const {
        highlightDeals,
        isLoadingHighlight,
        isErrorHighlight,
        messageHighlight,
    } = useSelector((state) => state.highlight);

    const {featuredDeals, isLoadingFeatured, isErrorFeatured, messageFeatured} =
        useSelector((state) => state.featured);

    const {vendors, isLoadingVendors, isErrorVendors, messageVendors} =
        useSelector((state) => state.vendor);

    const dispatch = useDispatch();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5,
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
        },
    };

    // useEffect(() => {
    //     getDeals(dispatch);
    // }, [dispatch]);

    useEffect(() => {
        if (isError) {
            console.log(message);
        }

        dispatch(getBestDeals());

        return () => {
            dispatch(reset());
        };
    }, [isError, message, dispatch]);

    useEffect(() => {
        if (isErrorHighlight) {
            console.log(messageHighlight);
        }

        dispatch(getHighlightDeals());

        return () => {
            dispatch(resetHighlight());
        };
    }, [isErrorHighlight, messageHighlight, dispatch]);

    useEffect(() => {
        if (isErrorFeatured) {
            console.log(messageFeatured);
        }

        dispatch(getFeaturedDeals());

        return () => {
            dispatch(resetFeatured());
        };
    }, [isErrorFeatured, messageFeatured, dispatch]);

    useEffect(() => {
        if (isErrorVendors) {
            console.log(messageVendors);
        }

        dispatch(getAllVendorData());

        return () => {
            dispatch(resetVendors());
        };
    }, [isErrorVendors, messageVendors, dispatch]);

    const truncate = function (str) {
        return str.length > 10 ? str.substring(0, 120) + "..." : str;
    };

    return (
        <div>
            <Header type="wImg" />
            <section className="hero__section">
                <div className="hero__content">
                    <h5>Plan Your Travel Now!</h5>
                    <p>
                        Find our currated accommodation, F&#38;B, tours,
                        activities, and souvenir in Labuan Bajo
                    </p>
                    <div className="search__bar">
                        <input
                            type="email"
                            placeholder="Search activities or packages"
                        />
                        <span>
                            <i className="ri-search-line"></i>
                        </span>
                    </div>
                </div>
                <div className="hero__img">
                    <img src={heroImg} alt="hero-img" className="w-100" />
                </div>
            </section>

            <section className="section__highlight">
                {highlightDeals.map((item, index) => (
                    <Highlight key={index} item={item} />
                ))}
            </section>

            <section className="deals__section">
                <Container>
                    <Row>
                        <Col
                            lg="12"
                            className="best__deals d-flex align-items-center"
                        >
                            <div>
                                <h4 className="heading__title">
                                    Best Deals In This Month
                                </h4>
                                <p className="heading__paragraph">
                                    We have gathered some of our best deals in
                                    this month, keep scrolling and find your
                                    best deals
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <Carousel
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={3200}
                                    className="featured__deals-slider"
                                >
                                    {currentDeals.map((item) => (
                                        <DealsCard key={item._id} item={item} />
                                    ))}
                                </Carousel>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="deals__section" style={{background: "#f8f8f8"}}>
                <Container>
                    <Row>
                        <Col
                            lg="12"
                            className="best__deals d-flex align-items-center"
                        >
                            <div>
                                <h4 className="heading__title">
                                    Featured Deals
                                </h4>
                                <p className="heading__paragraph">
                                    Make it easy with summary of featured deals
                                    and plan your fantastic gateway
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="desktop">
                        {featuredDeals.map((item, index) =>
                            !(index % 2) ? (
                                <Row key={index} className="mb-5">
                                    <Col lg="7">
                                        <img
                                            src={item?.img?.secure_url}
                                            alt="deals-img"
                                            className="w-100 featured__img"
                                        />
                                    </Col>
                                    <Col lg="5" className="featured__content">
                                        <h5>{item.title}</h5>
                                        <div className="excerpt">
                                            {HTMLReactParser(
                                                `${truncate(item.desc)}`
                                            )}
                                        </div>
                                        <div className="link__view">
                                            <Link
                                                to={`/deal-details/${item._id}`}
                                            >
                                                View Details
                                                <i className="ri-arrow-right-s-line"></i>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row key={index} className="mb-5">
                                    <Col lg="5" className="featured__content">
                                        <h5>{item.title}</h5>
                                        <div className="excerpt">
                                            {HTMLReactParser(
                                                `${truncate(item.desc)}`
                                            )}
                                        </div>
                                        <div className="link__view">
                                            <Link
                                                to={`/deal-details/${item._id}`}
                                            >
                                                View Details
                                                <i className="ri-arrow-right-s-line"></i>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <img
                                            src={item?.img?.secure_url}
                                            alt="deals-img"
                                            className="w-100 featured__img"
                                        />
                                    </Col>
                                </Row>
                            )
                        )}
                    </div>
                    <div className="mobile">
                        {featuredDeals.map((item, index) => (
                            <Row key={index} className="mb-5">
                                <Col lg="7">
                                    <img
                                        src={item?.img?.secure_url}
                                        alt="deals-img"
                                        className="w-100 featured__img"
                                    />
                                </Col>
                                <Col lg="5" className="featured__content">
                                    <h5>{item.title}</h5>
                                    <div className="excerpt">
                                        {HTMLReactParser(
                                            `${truncate(item.desc)}`
                                        )}
                                    </div>
                                    <div className="link__view">
                                        <Link to={`/deal-details/${item._id}`}>
                                            View Details
                                            <i className="ri-arrow-right-s-line"></i>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Container>
            </section>

            <section className="brand__section">
                <Container>
                    <Row>
                        <Col
                            lg="12"
                            className="best__deals d-flex align-items-center"
                        >
                            <div>
                                <h4 className="heading__title">Our Partners</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            className="featured__deals-slider"
                        >
                            {vendors.map((item, index) => (
                                <div className="brand__logo" key={index}>
                                    <img
                                        src={item?.logo?.secure_url}
                                        alt="brand-img"
                                        className="logo__brand-img"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default Home;
