import {createSlice} from "@reduxjs/toolkit";

import {toast} from "react-toastify";

const resetSlice = createSlice({
    name: "reset",
    initialState: {
        isFetching: false,
        error: false,
    },
    reducers: {
        resetStart: (state) => {
            state.isFetching = true;
        },
        resetSuccess: (state, action) => {
            state.isFetching = false;

            toast.success("Please login to your account using new password.", {
                position: "bottom-left",
            });

            //state.currentUser = action.payload;

            //localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
        },
        resetFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const resetActions = resetSlice.actions;
export default resetSlice.reducer;
