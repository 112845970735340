import React, {useEffect, useState} from "react";

import {Container, Row, Col} from "reactstrap";

import heroImg2 from "../assets/image/header-cat.jpg";

import "../styles/hero-section.css";
import "../styles/profile.css";

import DealsList from "../components/DealsList";
import Header from "../components/Header";
import Footer from "../components/Footer";

import {useSelector, useDispatch} from "react-redux";
import {getDeals, updateProfile} from "../redux/apiCalls";
import {getAllDeals, getDealsByCategoryData, reset} from "../redux/dealsRedux";
import {getAllCategoriesData, resetAllCategory} from "../redux/categoryRedux";

import {Link, useParams} from "react-router-dom";

import {motion} from "framer-motion";

import {toast} from "react-toastify";
import {publicRequest} from "../requestMethods";

const Profile = () => {
    const userData = useSelector((state) => state.user.currentUser);

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");

    const dispatch = useDispatch();

    const {isFetching, error} = useSelector((state) => state.profile);

    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const res = await publicRequest.get(
                    "/auth/userfind/" + userData._id
                );
                setFname(res.data.fname);
                setLname(res.data.lname);
                setEmail(res.data.email);
                setPhone(res.data.phone);
                setCountry(res.data.country);
            } catch (error) {
                console.log(error);
            }
        };

        getUserProfile();
    }, [userData]);

    const handleClick = (e) => {
        e.preventDefault();
        if (fname === "") {
            toast.error("First name is required.", {position: "bottom-left"});
        } else if (lname === "") {
            toast.error("Last name is required.", {position: "bottom-left"});
        } else if (email === "") {
            toast.error("Email is required.", {position: "bottom-left"});
        } else {
            updateProfile(dispatch, {
                _id: userData._id,
                fname,
                lname,
                email,
                phone,
                country,
            });

            if (error === false) {
                // navigate("/login");
                toast.success("Profile updated!", {
                    position: "bottom-left",
                });
            }
        }
    };

    return (
        <div>
            <Header type="woImg" />

            <section className="profile__section">
                <Container>
                    <Row>
                        <Col lg="3" className="profile_nav__column">
                            <div className="avatar">
                                <div className="avatar_icon">
                                    <i className="ri-user-line"></i>
                                </div>
                                <h5 className="profile_email">{email}</h5>
                            </div>
                            <ul>
                                <Link to={`/profile`}>
                                    <li>Profile</li>
                                </Link>

                                <Link to={`/order-history`}>
                                    <li>Order History</li>
                                </Link>
                            </ul>
                        </Col>
                        <Col lg="9" className="profile__column">
                            <form className="form mb-5">
                                <Row>
                                    <Col lg="6">
                                        <div className="form__group gap-2">
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                value={fname}
                                                onChange={(e) =>
                                                    setFname(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="form__group gap-2">
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                value={lname}
                                                onChange={(e) =>
                                                    setLname(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div className="form__group gap-2">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                required
                                                readOnly
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="form__group gap-2">
                                            <input
                                                type="number"
                                                placeholder="Phone"
                                                value={phone}
                                                onChange={(e) =>
                                                    setPhone(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div className="form__group gap-2">
                                            <input
                                                type="text"
                                                placeholder="Country"
                                                value={country}
                                                onChange={(e) =>
                                                    setCountry(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <button
                                    type="submit"
                                    disabled={isFetching}
                                    onClick={handleClick}
                                    className="login__btn"
                                >
                                    {isFetching ? "Loading..." : "Update"}
                                </button>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default Profile;
