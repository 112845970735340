import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCategories, getNavCategories } from "./apiCalls";

const initialState = {
    navCategories: [],
    isErrorNavCategories: false,
    isSuccessNavCategories: false,
    isLoadingNavCategories: false,
    messageNavCategories: "",
    allCategories: [],
    isErrorAllCategories: false,
    isSuccessAllCategories: false,
    isLoadingAllCategories: false,
    messageAllCategories: "",
};

// Get Highlight
export const getNavCategoriesData = createAsyncThunk(
    "categories/getNavCategoriesData",
    async (_, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await getNavCategories();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get Highlight
export const getAllCategoriesData = createAsyncThunk(
    "categories/getAllCategoriesData",
    async (_, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await getAllCategories();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        resetNavCategory: (state) => {
            state.navCategories = [];
            state.isErrorNavCategories = false;
            state.isSuccessNavCategories = false;
            state.isLoadingNavCategories = false;
            state.messageNavCategories = "";
        },
        resetAllCategory: (state) => {
            state.allCategories = [];
            state.isErrorAllCategories = false;
            state.isSuccessAllCategories = false;
            state.isLoadingAllCategories = false;
            state.messageAllCategories = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNavCategoriesData.pending, (state) => {
                state.isLoadingNavCategories = true;
            })
            .addCase(getNavCategoriesData.fulfilled, (state, action) => {
                state.isLoadingNavCategories = false;
                state.isSuccessNavCategories = true;
                state.navCategories = action.payload;
            })
            .addCase(getNavCategoriesData.rejected, (state, action) => {
                state.isLoadingNavCategories = false;
                state.isErrorNavCategories = true;
                state.messageNavCategories = action.payload;
            })
            .addCase(getAllCategoriesData.pending, (state) => {
                state.isLoadingAllCategories = true;
            })
            .addCase(getAllCategoriesData.fulfilled, (state, action) => {
                state.isLoadingAllCategories = false;
                state.isSuccessAllCategories = true;
                state.allCategories = action.payload;
            })
            .addCase(getAllCategoriesData.rejected, (state, action) => {
                state.isLoadingAllCategories = false;
                state.isErrorAllCategories = true;
                state.messageAllCategories = action.payload;
            });
    },
});

export const { resetNavCategory, resetAllCategory } = categorySlice.actions;
export default categorySlice.reducer;
