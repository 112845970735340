import React, {useState} from "react";
import {Link} from "react-router-dom";

import {Container, Row, Col} from "reactstrap";
import {publicRequest} from "../requestMethods";

import "../styles/footer.css";

import {useSelector, useDispatch} from "react-redux";
import {getSubs, resetSubs} from "../redux/newsletterRedux";

const Footer = () => {
    const [email, setEmail] = useState("");

    const {isErrorSubs, isSuccessSubs, isLoadingSubs, messageSubs} =
        useSelector((state) => state.newsletter);

    const dispatch = useDispatch();

    const handleClick = () => {
        if (isErrorSubs) {
            console.log(messageSubs);
        }

        dispatch(getSubs(email));

        return () => {
            dispatch(resetSubs());
        };
    };

    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col lg="3" md="4" sm="6" className="footer__touch">
                        <h5 className="footer__title">GET IN TOUCH</h5>
                        <p>
                            Kawasan Marina
                            <br />
                            Jalan Soekarno Hatta, Labuan Bajo
                            <br />
                            West Manggarai, NTT 86711
                        </p>
                        <p>
                            Phone: +62 385 2440234
                            <br />
                            E-mail: stay@meruorah.com
                            <br />
                            Website:{" "}
                            <a
                                href="https://meruorahlabuanbajo.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                meruorahlabuanbajo.com
                            </a>
                        </p>
                    </Col>
                    <Col lg="3" md="4" sm="6" className="footer__help">
                        <h5 className="footer__title">HELP</h5>
                        <ul>
                            <li>
                                <Link to={`/about-us`}>About Us</Link>
                            </li>
                            <li>
                                <Link to={`/contact`}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={`/privacy-policy`}>
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="3" md="4" sm="6" className="footer__partner">
                        <h5 className="footer__title">OUR BRANDS</h5>
                        <ul>
                            <li>
                                <a
                                    href="https://meruorahlabuanbajo.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Meruorah Komodo Labuan Bajo
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://meruorahlabuanbajo.com/loyalty"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Meruorah Loyalty Program
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="3" md="4" sm="6">
                        <h5 className="footer__title">NEWSLETTER</h5>
                        <p>Get the latest updates and offers.</p>
                        <div className="newsletter">
                            <input
                                type="email"
                                placeholder="Enter your e-mail"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span onClick={handleClick}>
                                {isLoadingSubs ? (
                                    <span>Loading...</span>
                                ) : (
                                    <i className="ri-send-plane-line"></i>
                                )}
                            </span>
                        </div>
                        {isSuccessSubs && <span>Subsribed success!</span>}
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col lg="12" md="12">
                        <p className="copyright__text">
                            2022 Meruorah Deals. All Right Reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
