import {useState} from "react";

import {Container, Row, Col} from "reactstrap";
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import {resetPassword} from "../redux/apiCalls";
import {useDispatch, useSelector} from "react-redux";

import "../styles/login.css";
import Header from "../components/Header";

import {motion} from "framer-motion";
import {Link, useNavigate, useParams} from "react-router-dom";

const ResetPassword = () => {
    const param = useParams();
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const {isFetching} = useSelector((state) => state.reset);

    const handleClick = (e) => {
        e.preventDefault();
        resetPassword(dispatch, {password, code: param.code});

        navigate(`/login`);
    };

    return (
        <div>
            <Header type="woImg" />
            <section className="login__section">
                <Container>
                    <Row>
                        <Col
                            lg="5"
                            md="6"
                            sm="12"
                            className="m-auto text-center login__content"
                        >
                            <h5>Create a new password</h5>
                            <div className="desc"></div>
                            <form className="form">
                                <div className="form__group gap-2">
                                    <i className="ri-lock-line"></i>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="login__btn"
                                    disabled={isFetching}
                                    onClick={handleClick}
                                >
                                    {isFetching
                                        ? "Loading..."
                                        : "Reset Password"}
                                </button>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default ResetPassword;
