import {createSlice} from "@reduxjs/toolkit";

import {toast} from "react-toastify";

const forgotSlice = createSlice({
    name: "forgot",
    initialState: {
        isFetching: false,
        isSending: false,
        error: false,
    },
    reducers: {
        forgotStart: (state) => {
            state.isFetching = true;
            state.isSending = false;
        },
        forgotSuccess: (state, action) => {
            state.isFetching = false;

            toast.success(
                "Please check your email inbox for a link to complete the reset.",
                {position: "top-right"}
            );

            state.isSending = true;

            //state.currentUser = action.payload;

            //localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
        },
        forgotFailure: (state) => {
            state.isFetching = false;
            state.isSending = false;
            state.error = true;
        },
    },
});

export const forgotActions = forgotSlice.actions;
export default forgotSlice.reducer;
