import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDeals, getDealsByCategory } from "./apiCalls";

const initialState = {
    currentDeals: [],
    allDeals: [],
    categoryDeals: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    isErrorCategoryDeals: false,
    isSuccessCategoryDeals: false,
    isLoadingCategoryDeals: false,
    messageCategoryDeals: "",
};

// Get Best Deals
export const getBestDeals = createAsyncThunk(
    "deals/getBestDeals",
    async (_, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await getDeals();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get All Deals
export const getAllDeals = createAsyncThunk(
    "deals/getAllDeals",
    async (_, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await getDeals();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get Deals By Category
export const getDealsByCategoryData = createAsyncThunk(
    "deals/getDealsByCategoryData",
    async (id, thunkAPI) => {
        try {
            //const token = thunkAPI.getState().auth.user.accessToken;
            return await getDealsByCategory(id);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const dealsSlice = createSlice({
    name: "deals",
    initialState,
    reducers: {
        reset: (state) => initialState,
        resetCategoryDeals: (state) => {
            state.isErrorCategoryDeals = false;
            state.isSuccessCategoryDeals = false;
            state.isLoadingCategoryDeals = false;
            state.messageCategoryDeals = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBestDeals.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBestDeals.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.currentDeals = action.payload;
            })
            .addCase(getBestDeals.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getAllDeals.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDeals.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.allDeals = action.payload;
            })
            .addCase(getAllDeals.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getDealsByCategoryData.pending, (state) => {
                state.isLoadingCategoryDeals = true;
            })
            .addCase(getDealsByCategoryData.fulfilled, (state, action) => {
                state.isLoadingCategoryDeals = false;
                state.isSuccessCategoryDeals = true;
                state.categoryDeals = action.payload;
            })
            .addCase(getDealsByCategoryData.rejected, (state, action) => {
                state.isLoadingCategoryDeals = false;
                state.isErrorCategoryDeals = true;
                state.messageCategoryDeals = action.payload;
            });
    },
});

export const { reset, resetCategoryDeals } = dealsSlice.actions;
export default dealsSlice.reducer;
