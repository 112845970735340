import React from "react";
import About from "../pages/About";
import CategoryListing from "../pages/CategoryListing";
import Contact from "../pages/Contact";
import DealsDetails from "../pages/DealsDetails";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import ScrollToTop from "../components/ScrollToTop";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {useDispatch, useSelector} from "react-redux";

import {Routes, Route, Navigate, useLocation} from "react-router-dom";

import {AnimatePresence} from "framer-motion";
import Privacy from "../pages/Privacy";
import {useEffect} from "react";
import {getAllDeals} from "../redux/dealsRedux";
import EmailVerify from "../pages/EmailVerify";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Profile from "../pages/Profile";
import OrderHistory from "../pages/OrderHistory";

function AnimatedRoutes() {
    const userData = useSelector((state) => state.user.currentUser);
    const cartPackages = useSelector((state) => state.cart.cartItems);

    const location = useLocation();

    return (
        <AnimatePresence>
            <ScrollToTop>
                <ToastContainer />
                <Routes location={location} key={location.pathname}>
                    <Route path="/" exact element={<Home />} />
                    <Route
                        path="/category/:categoryId"
                        element={<CategoryListing />}
                    />
                    <Route
                        path="/deal-details/:dealId"
                        element={<DealsDetails />}
                    />
                    <Route path="/cart" element={<Cart />} />
                    <Route
                        path="/checkout"
                        element={
                            cartPackages.length > 0 ? (
                                <Checkout />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route
                        path="/login"
                        element={
                            !!userData && userData?.accessToken ? (
                                <Navigate to="/" />
                            ) : (
                                <Login />
                            )
                        }
                    />
                    <Route
                        path="/register"
                        element={
                            !!userData && userData?.accessToken ? (
                                <Navigate to="/" />
                            ) : (
                                <Register />
                            )
                        }
                    />
                    <Route
                        path="/forgot-password"
                        element={
                            !!userData && userData?.accessToken ? (
                                <Navigate to="/" />
                            ) : (
                                <ForgotPassword />
                            )
                        }
                    />
                    <Route
                        path="/member/resetpass/:code"
                        element={
                            !!userData && userData?.accessToken ? (
                                <Navigate to="/" />
                            ) : (
                                <ResetPassword />
                            )
                        }
                    />
                    <Route
                        path="/member/active/:verificationCode"
                        element={
                            !!userData && userData?.accessToken ? (
                                <Navigate to="/" />
                            ) : (
                                <EmailVerify />
                            )
                        }
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/order-history" element={<OrderHistory />} />
                </Routes>
            </ScrollToTop>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
