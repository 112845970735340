import {configureStore} from "@reduxjs/toolkit";

import cartReducer from "./cartRedux";
import userReducer from "./userRedux";
import googleReducer from "./googleRedux";
import registerRedux from "./registerRedux";
import profileReducer from "./profileRedux";
import forgotRedux from "./forgotRedux";
import resetRedux from "./resetRedux";
import dealsRedux from "./dealsRedux";
import highlightRedux from "./highlightRedux";
import featuredRedux from "./featuredRedux";
import orderRedux from "./orderRedux";
import categoryRedux from "./categoryRedux";
import vendorReducer from "./vendorRedux";
import newsletterReducer from "./newsletterRedux";

const store = configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer,
        google: googleReducer,
        register: registerRedux,
        forgot: forgotRedux,
        reset: resetRedux,
        deals: dealsRedux,
        order: orderRedux,
        highlight: highlightRedux,
        featured: featuredRedux,
        category: categoryRedux,
        vendor: vendorReducer,
        newsletter: newsletterReducer,
        profile: profileReducer,
    },
});

export default store;
