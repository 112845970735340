import {useState} from "react";

import {Container, Row, Col} from "reactstrap";
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import {forgotPassword} from "../redux/apiCalls";
import {useDispatch, useSelector} from "react-redux";

import "../styles/login.css";
import Header from "../components/Header";

import {motion} from "framer-motion";
import {Link} from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();
    const {isFetching, isSending} = useSelector((state) => state.forgot);

    const handleClick = (e) => {
        e.preventDefault();
        forgotPassword(dispatch, {email});
    };

    return (
        <div>
            <Header type="woImg" />
            <section className="login__section">
                <Container>
                    <Row>
                        <Col
                            lg="5"
                            md="6"
                            sm="12"
                            className="m-auto text-center login__content"
                        >
                            <h5>Reset your password</h5>
                            {isSending ? (
                                <div className="desc">
                                    Please check your email inbox for a link to
                                    complete the reset.
                                </div>
                            ) : (
                                <>
                                    <div className="desc">
                                        To reset your password, enter your email
                                        below and submit. An email will be sent
                                        to you with instructions about how to
                                        complete the process.
                                    </div>
                                    <form className="form">
                                        <div className="form__group gap-2">
                                            <i className="ri-mail-line"></i>
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="login__btn"
                                            disabled={isFetching}
                                            onClick={handleClick}
                                        >
                                            {isFetching
                                                ? "Loading..."
                                                : "Reset Password"}
                                        </button>
                                    </form>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default ForgotPassword;
